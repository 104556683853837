import Script from 'next/script';
import React from 'react';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const GoogleAnalytics = () => {
  const GATRACKINGID = publicRuntimeConfig.GATRACKINGID;
  return (
    <>
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${GATRACKINGID}`} />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GATRACKINGID}');
          `,
        }}
      />
    </>
  );
};
export default GoogleAnalytics;
