import { useEffect, useState, createContext } from 'react';
import { getCookie } from 'cookies-next';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';

import { IMenu } from '../common';

const { publicRuntimeConfig } = getConfig();

const Loader = dynamic(() => import('../Loader'), {
  loading: () => <p>....Loading.....</p>,
});

const Header = dynamic(() => import('../Header').then((mod)=>mod.Header), {
  loading: () => <Loader isLoading />,
});

const MegaFooter = dynamic(() => import('../Footer').then((mod)=>mod.MegaFooter), {
  loading: () => <Loader isLoading />,
});

const ContentWrapper = dynamic(() => import('./contentWrapper'), {
  loading: () => <Loader isLoading />,
});

const ApplicationWrapper = dynamic(() => import('./style').then((mod)=>mod.ApplicationWrapper), {
  loading: () => <Loader isLoading />,
});

export const MenuContext = createContext<IMenu[]>([]);

export interface ILayoutProps {
  children: JSX.Element;
}

export const Layout = ({ children }: ILayoutProps) => {
  const defaultCategories: [] = [];
  const [currentCategory, setCurrentCategories] = useState<IMenu[]>(defaultCategories);

  const menu = getCookie(publicRuntimeConfig.COOKIENAME);

  useEffect(() => {
    if(menu){
      setCurrentCategories(JSON.parse(menu as string));
    }
   }, [menu]);

  return (
    <MenuContext.Provider value={currentCategory}>
      <ApplicationWrapper>
        <Header />
        <ContentWrapper>{children}</ContentWrapper>
        <MegaFooter title="Our Services" />
      </ApplicationWrapper>
    </MenuContext.Provider>
  );
};
